import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn, FetchArgs } from "@reduxjs/toolkit/query/react";
import type { ErrorData } from "app/types/error.ts";

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL,
  prepareHeaders: (headers) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
}) as BaseQueryFn<string | FetchArgs, unknown, ErrorData>;

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [
    "basins",
    "bolCounties",
    "bolMarkets",
    "bols",
    "bolImages",
    "bolProducts",
    "carriers",
    "counties",
    "customerContacts",
    "customers",
    "drivers",
    "jobTypes",
    "jobTypeGroups",
    "loadOrigins",
    "mailerTemplates",
    "markets",
    "notificationTemplates",
    "opisnetRolfsonMap",
    "orders",
    "ordersBulkUpload",
    "orderStatus",
    "products",
    "refineries",
    "refineryAddresses",
    "resources",
    "rigs",
    "rolePermissions",
    "roles",
    "salesReps",
    "settings",
    "taxes",
    "ticket",
    "ticketProducts",
    "ticketTaxes",
    "ticketTypes",
    "tickets",
    "timeSlots",
    "trucks",
    "units",
    "users",
    "vendors",
  ],
});
