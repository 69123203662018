import type { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import type { RouteObject } from "react-router-dom";
import { useAppSelector } from "app/hooks.ts";
import { selectUser } from "features/auth/authSlice.ts";
import { selectPermissions } from "features/permissions/permissionsSlice.ts";
import type { PermissionsParsed } from "features/permissions/permissionsTypes.ts";

const PublicRoute = () => {
  const user = useAppSelector(selectUser);
  const permissions = useAppSelector(selectPermissions);

  if (user && permissions) {
    return <Navigate to="/summary" replace />;
  }
  return <Outlet />;
};

const ProtectedRoute = (props: {
  id?: keyof PermissionsParsed;
  children: ReactElement;
}) => {
  const user = useAppSelector(selectUser);
  const permissions = useAppSelector(selectPermissions);

  if (user && permissions) {
    if (props.id && permissions[props.id]?.isRead) {
      return props.children;
    } else {
      return <Navigate to="/notfound" replace />;
    }
  }
  return <Navigate to="/" replace />;
};

const NotFoundRoute = (props: { children: ReactElement }) => {
  const user = useAppSelector(selectUser);
  const permissions = useAppSelector(selectPermissions);

  if (user && permissions) {
    return props.children;
  }
  return <Navigate to="/" replace />;
};
export const routes: RouteObject[] = [
  {
    element: <PublicRoute />,
    children: [
      {
        path: "/",
        element: <Navigate to="/login" replace />,
      },
      {
        path: "/login",
        async lazy() {
          let { Login } = await import("features/auth/views/Login.tsx");
          return { Component: Login };
        },
      },
      {
        path: "*",
        element: <Navigate to="/login" replace />,
      },
    ],
  },
  {
    path: "/",
    async lazy() {
      let { Summary } = await import("features/summary/views/Summary.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="DashboardPage">
            <Summary />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/summary",
    async lazy() {
      let { Summary } = await import("features/summary/views/Summary.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="DashboardPage">
            <Summary />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/orders",
    async lazy() {
      let { Orders } = await import("features/orders/views/Orders.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="OrdersPage">
            <Orders />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/orders/edit/:id",
    handle: "Orders",
    async lazy() {
      let { OrderEditor } = await import(
        "features/orders/views/OrderEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="OrdersPage">
            <OrderEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/orders/bulkupload",
    handle: "Orders",
    async lazy() {
      let { OrdersBulkUploader: OrdersBulkUploader } = await import(
        "features/ordersBulkUploads/views/OrdersBulkUploader"
      );
      return {
        Component: () => (
          <ProtectedRoute id="OrdersPage">
            <OrdersBulkUploader />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/tickets",
    async lazy() {
      let { Tickets } = await import("features/tickets/views/Tickets.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="TicketsPage">
            <Tickets />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/tickets/edit/:id",
    handle: "Tickets",
    async lazy() {
      let { TicketEditor } = await import(
        "features/tickets/views/TicketEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="TicketsPage">
            <TicketEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/customers",
    async lazy() {
      let { Customers } = await import(
        "features/customers/views/Customers.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="CustomersPage">
            <Customers />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/customers/edit/:id",
    handle: "Customers",
    async lazy() {
      let { CustomerEditor } = await import(
        "features/customers/views/CustomerEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="CustomersPage">
            <CustomerEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/customers/contacts",
    async lazy() {
      let { CustomerContacts } = await import(
        "features/customerContacts/views/CustomerContacts.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="CustomersPage">
            <CustomerContacts />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/customers/contacts/edit/:id",
    handle: "Customer contacts",
    async lazy() {
      let { CustomerContactEditor } = await import(
        "features/customerContacts/views/CustomerContactEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="CustomersPage">
            <CustomerContactEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/customers/basins",
    async lazy() {
      let { Basins } = await import("features/basins/views/Basins.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="CustomersPage">
            <Basins />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/customers/rigs",
    async lazy() {
      let { Rigs } = await import("features/rigs/views/Rigs.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Rigs">
            <Rigs />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/customers/rigs/edit/:id",
    handle: "Rigs",
    async lazy() {
      let { RigEditor } = await import("features/rigs/views/RigEditor.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Rigs">
            <RigEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/drivers",
    async lazy() {
      let { Drivers } = await import("features/drivers/views/Drivers.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="DriversPage">
            <Drivers />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/drivers/edit/:id",
    handle: "Drivers",
    async lazy() {
      let { DriverEditor } = await import(
        "features/drivers/views/DriverEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="DriversPage">
            <DriverEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/pricesmap",
    async lazy() {
      let { PricesMap } = await import("features/opis/views/PricesMap.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="PRICES">
            <PricesMap />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols",
    async lazy() {
      let { BOLs } = await import("features/bols/views/BOLs.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="BOLS">
            <BOLs />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/edit/:id",
    handle: "BOLs",
    async lazy() {
      let { BOLEditor } = await import("features/bols/views/BOLEditor.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="BOLS">
            <BOLEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/markets",
    async lazy() {
      let { BOLMarkets } = await import(
        "features/bolMarkets/views/BOLMarkets.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="BOL MARKETS">
            <BOLMarkets />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/markets/edit/:id",
    handle: "BOL Markets",
    async lazy() {
      let { BOLMarketEditor } = await import(
        "features/bolMarkets/views/BOLMarketEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="BOL MARKETS">
            <BOLMarketEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/counties",
    async lazy() {
      let { BOLCounties } = await import(
        "features/bolCounties/views/BOLCounties.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="BOL COUNTIES">
            <BOLCounties />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/counties/edit/:id",
    handle: "BOL Counties",
    async lazy() {
      let { BOLCountyEditor } = await import(
        "features/bolCounties/views/BOLCountyEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="BOL COUNTIES">
            <BOLCountyEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/carriers",
    async lazy() {
      let { Carriers } = await import("features/carriers/views/Carriers.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="CARRIERS">
            <Carriers />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/carriers/edit/:id",
    handle: "Carriers",
    async lazy() {
      let { CarrierEditor } = await import(
        "features/carriers/views/CarrierEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="CARRIERS">
            <CarrierEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/refineries",
    async lazy() {
      let { Refineries } = await import(
        "features/refineries/views/Refineries.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="REFINERIES">
            <Refineries />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/refineries/edit/:id",
    handle: "Refineries",
    async lazy() {
      let { RefineryEditor } = await import(
        "features/refineries/views/RefineryEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="REFINERIES">
            <RefineryEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/refineryAddresses",
    async lazy() {
      let { RefineryAddresses } = await import(
        "features/refineryAddresses/views/RefineryAddresses.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="REFINERY ADDRESSES">
            <RefineryAddresses />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/refineryAddresses/edit/:id",
    handle: "Refineries Addresses",
    async lazy() {
      let { RefineryAddressEditor } = await import(
        "features/refineryAddresses/views/RefineryAddressEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="REFINERY ADDRESSES">
            <RefineryAddressEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/vendors",
    async lazy() {
      let { Vendors } = await import("features/vendors/views/Vendors.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="VENDORS">
            <Vendors />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/bols/vendors/edit/:id",
    handle: "Vendors",
    async lazy() {
      let { VendorEditor } = await import(
        "features/vendors/views/VendorEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="VENDORS">
            <VendorEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets",
    async lazy() {
      let { Markets } = await import("features/markets/views/Markets.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Markets">
            <Markets />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/edit/:id",
    handle: "Markets",
    async lazy() {
      let { MarketEditor } = await import(
        "features/markets/views/MarketEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="Markets">
            <MarketEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/counties",
    async lazy() {
      let { Counties } = await import("features/counties/views/Counties.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Markets">
            <Counties />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/counties/edit/:id",
    handle: "Counties",
    async lazy() {
      let { CountyEditor } = await import(
        "features/counties/views/CountyEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="Markets">
            <CountyEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/loadorigins",
    async lazy() {
      let { LoadOrigins } = await import(
        "features/loadOrigins/views/LoadOrigins.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="LOADORIGIN">
            <LoadOrigins />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/loadorigins/edit/:id",
    handle: "Load origins",
    async lazy() {
      let { LoadOriginEditor } = await import(
        "features/loadOrigins/views/LoadOriginEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="LOADORIGIN">
            <LoadOriginEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/products",
    async lazy() {
      let { Products } = await import("features/products/views/Products.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="ProductsPage">
            <Products />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/products/edit/:id",
    handle: "Products",
    async lazy() {
      let { ProductEditor } = await import(
        "features/products/views/ProductEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="ProductsPage">
            <ProductEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/jobtypes",
    async lazy() {
      let { JobTypes } = await import("features/jobTypes/views/JobTypes.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="JobTypes">
            <JobTypes />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/jobtypegroups",
    async lazy() {
      let { JobTypeGroups } = await import(
        "features/jobTypeGroups/views/JobTypeGroups.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="JobTypes">
            <JobTypeGroups />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/jobtypes/edit/:id",
    handle: "Job types",
    async lazy() {
      let { JobTypeEditor } = await import(
        "features/jobTypes/views/JobTypeEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="JobTypes">
            <JobTypeEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/jobtypegroups/edit/:id",
    handle: "Job type Groups",
    async lazy() {
      let { JobTypeGroupEditor } = await import(
        "features/jobTypeGroups/views/jobTypeGroupEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="JobTypeGroups">
            <JobTypeGroupEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/tickettypes",
    async lazy() {
      let { TicketTypes } = await import(
        "features/ticketTypes/views/TicketTypes.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="TicketAdminPage">
            <TicketTypes />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/tickettypes/edit/:id",
    handle: "Ticket types",
    async lazy() {
      let { TicketTypeEditor } = await import(
        "features/ticketTypes/views/TicketTypeEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="TicketAdminPage">
            <TicketTypeEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/units",
    async lazy() {
      let { Units } = await import("features/units/views/Units.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Units">
            <Units />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/units/edit/:id",
    handle: "Units",
    async lazy() {
      let { UnitEditor } = await import("features/units/views/UnitEditor.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Units">
            <UnitEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/taxes",
    async lazy() {
      let { Taxes } = await import("features/taxes/views/Taxes.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Taxes">
            <Taxes />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/taxes/edit/:id",
    handle: "Taxes",
    async lazy() {
      let { TaxEditor } = await import("features/taxes/views/TaxEditor.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Taxes">
            <TaxEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/trucks",
    async lazy() {
      let { Trucks } = await import("features/trucks/views/Trucks.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="TruckPage">
            <Trucks />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/markets/trucks/edit/:id",
    handle: "Trucks",
    async lazy() {
      let { TruckEditor } = await import(
        "features/trucks/views/TruckEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="TruckPage">
            <TruckEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/users",
    async lazy() {
      let { Users } = await import("features/users/views/Users.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Users">
            <Users />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/users/edit/:id",
    handle: "Users",
    async lazy() {
      let { UserEditor } = await import("features/users/views/UserEditor.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Users">
            <UserEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/users/salesreps",
    async lazy() {
      let { SalesReps } = await import(
        "features/salesReps/views/SalesReps.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="SalesReps">
            <SalesReps />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/users/salesreps/edit/:id",
    handle: "Sales reps",
    async lazy() {
      let { SalesRepEditor } = await import(
        "features/salesReps/views/SalesRepEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="SalesReps">
            <SalesRepEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/users/roles",
    async lazy() {
      let { Roles } = await import("features/roles/views/Roles.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Roles">
            <Roles />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/users/roles/edit/:id",
    handle: "Roles",
    async lazy() {
      let { RoleEditor } = await import("features/roles/views/RoleEditor.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="Roles">
            <RoleEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/users/resources",
    async lazy() {
      let { Resources } = await import(
        "features/resources/views/Resources.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="PageModules">
            <Resources />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/users/resources/edit/:id",
    handle: "Resources",
    async lazy() {
      let { ResourceEditor } = await import(
        "features/resources/views/ResourceEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="PageModules">
            <ResourceEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings",
    async lazy() {
      let { Settings } = await import("features/settings/views/Settings.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="SettingsList">
            <Settings />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/edit/:id",
    handle: "Settings",
    async lazy() {
      let { SettingEditor } = await import(
        "features/settings/views/SettingEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="SettingsList">
            <SettingEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/mailertemplates",
    async lazy() {
      let { MailerTemplates } = await import(
        "features/mailerTemplates/views/MailerTemplates.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="SettingsList">
            <MailerTemplates />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/mailertemplates/edit/:id",
    handle: "Mailer templates",
    async lazy() {
      let { MailerTemplateEditor } = await import(
        "features/mailerTemplates/views/MailerTemplateEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="SettingsList">
            <MailerTemplateEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/notificationtemplates",
    async lazy() {
      let { NotificationTemplates } = await import(
        "features/notificationTemplates/views/NotificationTemplates.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="SettingsList">
            <NotificationTemplates />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/notificationtemplates/edit/:id",
    handle: "Notification templates",
    async lazy() {
      let { NotificationTemplateEditor } = await import(
        "features/notificationTemplates/views/NotificationTemplateEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="SettingsList">
            <NotificationTemplateEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/timeslots",
    async lazy() {
      let { TimeSlots } = await import(
        "features/timeSlots/views/TimeSlots.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="TicketAdminPage">
            <TimeSlots />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/timeslots/edit/:id",
    handle: "Time slots",
    async lazy() {
      let { TimeSlotEditor } = await import(
        "features/timeSlots/views/TimeSlotEditor.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="TicketAdminPage">
            <TimeSlotEditor />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/customerlogs",
    async lazy() {
      let { CustomerLogs } = await import(
        "features/customerLogs/views/CustomerLogs.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="SettingsList">
            <CustomerLogs />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/orderlogs",
    async lazy() {
      let { OrderLogs } = await import(
        "features/orderLogs/views/OrderLogs.tsx"
      );
      return {
        Component: () => (
          <ProtectedRoute id="SettingsList">
            <OrderLogs />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/settings/riglogs",
    async lazy() {
      let { RigLogs } = await import("features/rigLogs/views/RigLogs.tsx");
      return {
        Component: () => (
          <ProtectedRoute id="SettingsList">
            <RigLogs />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: "/notfound",
    async lazy() {
      let { PageNotFound } = await import(
        "components/organisms/PageNotFound/PageNotFound.tsx"
      );
      return {
        Component: () => (
          <NotFoundRoute>
            <PageNotFound />
          </NotFoundRoute>
        ),
      };
    },
  },
];
