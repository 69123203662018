import {
  configureStore,
  createListenerMiddleware,
  isRejected,
} from "@reduxjs/toolkit";
import { apiSlice } from "app/api/apiSlice.ts";
import alertsReducer, {
  addAlert as addAlertReducer,
} from "features/alerts/alertSlice.ts";
import { formatAlert } from "features/alerts/helpers.ts";
import authReducer from "features/auth/authSlice.ts";
import bolCountyReducer from "features/bolCounties/bolCountiesSlice.ts";
import bolMarketReducer from "features/bolMarkets/bolMarketsSlice.ts";
import bolReducer from "features/bols/bolsSlice.ts";
import carrierReducer from "features/carriers/carriersSlice.ts";
import countyReducer from "features/counties/countiesSlice.ts";
import customerContactReducer from "features/customerContacts/customerContactsSlice.ts";
import customerLogReducer from "features/customerLogs/customerLogsSlice.ts";
import customerReducer from "features/customers/customersSlice.ts";
import driverReducer from "features/drivers/driversSlice.ts";
import jobTypeGroupsReducer from "features/jobTypeGroups/jobTypeGroupsSlice";
import jobTypeReducer from "features/jobTypes/jobTypesSlice.ts";
import loadOriginReducer from "features/loadOrigins/loadOriginsSlice.ts";
import mailerTemplateReducer from "features/mailerTemplates/mailerTemplatesSlice.ts";
import marketReducer from "features/markets/marketsSlice.ts";
import notificationTemplateReducer from "features/notificationTemplates/notificationTemplatesSlice.ts";
import orderLogReducer from "features/orderLogs/orderLogsSlice.ts";
import orderReducer from "features/orders/ordersSlice.ts";
import ordersBulkUploadReducer from "features/ordersBulkUploads/ordersBulkUploadSlice.ts";
import permissionReducer from "features/permissions/permissionsSlice.ts";
import productReducer from "features/products/productsSlice.ts";
import refineryReducer from "features/refineries/refineriesSlice.ts";
import refineryAddressReducer from "features/refineryAddresses/refineryAddressesSlice.ts";
import resourceReducer from "features/resources/resourcesSlice.ts";
import rigLogReducer from "features/rigLogs/rigLogsSlice.ts";
import rigReducer from "features/rigs/rigsSlice.ts";
import roleReducer from "features/roles/rolesSlice.ts";
import salesRepReducer from "features/salesReps/salesRepsSlice.ts";
import settingReducer from "features/settings/settingsSlice.ts";
import taxReducer from "features/taxes/taxesSlice.ts";
import ticketTypeReducer from "features/ticketTypes/ticketTypesSlice.ts";
import ticketReducer from "features/tickets/ticketsSlice.ts";
import timeSlotReducer from "features/timeSlots/timeSlotsSlice.ts";
import truckReducer from "features/trucks/truckSlice.ts";
import unitReducer from "features/units/unitsSlice.ts";
import userReducer from "features/users/usersSlice.ts";
import vendorReducer from "features/vendors/vendorsSlice.ts";

const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  matcher: isRejected,
  effect: async (action, listenerApi) => {
    const error = action.payload;
    if (error) listenerApi.dispatch(addAlertReducer(formatAlert(error)));
  },
});

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    alerts: alertsReducer,
    auth: authReducer,
    bolCounties: bolCountyReducer,
    bolMarkets: bolMarketReducer,
    bols: bolReducer,
    carriers: carrierReducer,
    counties: countyReducer,
    customerContacts: customerContactReducer,
    customerLogs: customerLogReducer,
    customers: customerReducer,
    drivers: driverReducer,
    jobTypes: jobTypeReducer,
    jobTypeGroups: jobTypeGroupsReducer,
    loadOrigins: loadOriginReducer,
    mailerTemplates: mailerTemplateReducer,
    markets: marketReducer,
    notificationTemplates: notificationTemplateReducer,
    orderLogs: orderLogReducer,
    orders: orderReducer,
    ordersBulkUpload: ordersBulkUploadReducer,
    permissions: permissionReducer,
    products: productReducer,
    refineries: refineryReducer,
    refineryAddresses: refineryAddressReducer,
    resources: resourceReducer,
    rigLogs: rigLogReducer,
    rigs: rigReducer,
    roles: roleReducer,
    salesReps: salesRepReducer,
    settings: settingReducer,
    taxes: taxReducer,
    ticketTypes: ticketTypeReducer,
    tickets: ticketReducer,
    timeSlots: timeSlotReducer,
    trucks: truckReducer,
    units: unitReducer,
    users: userReducer,
    vendors: vendorReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
