import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface JobTypeGroupsFilters {
  search: string;
}

interface SortState {
  key: string;
  order: "asc" | "desc";
}

interface JobTypeGroupSlice {
  filter: JobTypeGroupsFilters;
  page: number;
  rowsPerPage: number;
  sort: SortState;
}

const initialState: JobTypeGroupSlice = {
  filter: { search: "" },
  page: 1,
  rowsPerPage: 10,
  sort: { key: "name", order: "asc" },
};

const jobTypeGroupsSlice = createSlice({
  name: "jobTypeGroups",
  initialState,
  reducers: {
    setFilter: (
      state,
      action: PayloadAction<[keyof JobTypeGroupsFilters, string | undefined]>,
    ) => {
      const [key, value] = action.payload;
      state.filter[key] = value || "";
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
    },
    setSort: (state, action: PayloadAction<SortState>) => {
      state.sort = action.payload;
    },
  },
});

export const { setFilter, setPage, setRowsPerPage, setSort } =
  jobTypeGroupsSlice.actions;

export const selectFilter = (state: any) =>
  state.jobTypeGroups?.filter || { search: "" };

export const selectPage = (state: any) => state.jobTypeGroups.page;
export const selectRowsPerPage = (state: any) =>
  state.jobTypeGroups.rowsPerPage;
export const selectSort = (state: any) => state.jobTypeGroups.sort;

export default jobTypeGroupsSlice.reducer;
